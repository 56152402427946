* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #EAEAEA;
}


.tel-link {
  text-decoration: none;
  color: #000;
  font-family: 'Roboto', 'Helvetica', 'sans-serif';
  font-weight: 600;
  font-size: 14px;
}


.quote {
  font-style: italic;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin-top: 15px;
}

.tel-link:hover {
  font-size: 15px;
  transition: ease-in-out 0.5s;
}

.showcase {
  background-image: url('../img/dd-showcase.jpg');
  background-position: center;
  background-size: cover;
  height: 500px;
  position: relative;
}

.mainNav {
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 20px 0px 20px 0px;
}

.navItems {
  display: flex;
}

.navItems li {
  padding-right: 75px;
  padding-top: 20px;
  list-style-type: none;
  font-family: arial;
  font-weight: 800;
  font-size: 14px;
}

.navItems a {
  text-decoration: none;
  color: #000;
}

.branding {
  color: #000;
}

.showcaseTitle {
  color: #fff;
  font-size: 30px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Roboto';
  font-weight: 900;
}

.btn {
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 150px;
  width: 150px;
  background-color: #566DCD;
  color: #fff;
  font-size: 10px;
}

#BookNow {
  font-family: 'Roboto', 'Helvetica', 'sans-serif';
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
}



.btn:hover {
  background-color: rgb(71, 90, 163);
  cursor: pointer;
}

.container {
  width: 95%;
  background-color: #fff;
  margin: 0 auto;
}

.intro {
  text-align: center;
  margin-top: 20px;
  width: 100%;
  display: block;
}

.halfFrame {
  width: 60%;
}

.aloha {
  font-size: 20px;
}

.frame-intro {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.halfFrame-intro {
  width: 100%;
  text-align: center;
  padding: 1px 20% 30px 20%;
  margin-top: 20px;
}

.halfFrame-intro p {
  font-family: 'Roboto', 'sans-serif';
  font-size: 13px;
}

.halfFrame {
  width: 100%;
  text-align: center;
  padding: 0px;
}


.halfFrameTwo {
  width: 50%;
  text-align: left;
  padding-left: 20px;
  padding-bottom: 500px;
}

.halfDay {
  width: 50%;
  text-align: left;
  padding-left: 20px;
  padding-bottom: 500px;
  background: url('../img/pillbox-compressor.jpg');
  background-size: cover;
  background-position: center;
}

.halfFrameTwoDesc h1 {
  font-size: 30px;
}

.halfFrameTwoDesc {
  width: 50%;
  text-align: left;
  padding: 40px;
  font-size: 13px;
}

.halfFrame p {
  text-align: left;
  font-size: 13px;
  padding-right: 20%;
}


.halfFrame h1 {
  padding-left: 20%;
  font-size: 45px;
}

.logo {
  width: 100px;
}

.halfFrameTwo {
  background-image: url('../img/donna1.jpg');
  background-position: center;
  background-size: cover;
}

.bookbtn {
  padding: 12px;
  border: none;
  border-radius: 150px;
  width: 150px;
  background-color: #566DCD;
  color: #fff;
  font-family: 'Roboto';
  font-weight: 900;
  cursor: pointer;
  margin-top: 5px;
}

.bookbtn:hover {
  background-color: rgb(71, 93, 179)
}

#Pearl {
  background: url('../img/pearl-harbor-compressor.jpg');
  background-position: center;
  background-size: cover;
}

#North {
  background: url('../img/horse.jpg');
  background-position: center;
  background-size: cover;
}

#East {
  background: url('../img/pillbox-compressor.jpg');
  background-position: center;
  background-size: cover;
}
#Flower {
  background: url('../img/flower2-compressor.jpg');
  background-position: center;
  background-size: cover;
}

#Culture {
  background: url('../img/food.jpg');
  background-position: center;
  background-size: cover;
}

.phone {
  width: 5px;
  margin-right: 10px;
}

.flex-container {
  display: flex;
}

.tourDesc {
  width: 60%;
}

.iframe {
  padding: 40px;
  margin-right: 20px;
}






@media screen and (max-width: 1000px) {

  .showcaseTitle {
    font-size: 20px;
  }

  .flex-container {
    display: block;
  }

  .tourDesc {
    width: 100%;
  }

  #North {
    width: 100%;
  }

  #East {
    width: 100%;
  }

  .btn {
    width: 40%;
    padding: 10px;
  }

  .intro {
    width: 100%;
    display: block;
  }

  .frame-intro {
    width: 100%;
    display: block;
  }

  .halfFrameTwo {
    width: 100%;
  }

  .halfFrameTwoDesc {
    width: 100%;
    text-align: center;
  }
  
  .halfFrame-intro {
    text-align: center;
    padding: 10;
  }

  #halfDay {
    background: url('../img/pillbox-compressor.jpg');
    background-position: center;
    background-size: cover;
  }



  
}

@media screen and (max-width: 900px) {
  .btn {
    width: 50%;
    padding: 10px;
  }

}