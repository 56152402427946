.footer {
  padding: 15px;
  margin-top: 20px;
  background-color: #566dcd;
}

.footer p {
  color: #fff;
  font-family: "Roboto", "sans-serif";
  font-weight: 900;
  font-size: 12px;
}

