h4 {
  padding-bottom: 10px;
}

.showValue {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;

}

.hideValue {
  display: none;
}

#prices {
  margin: 20px 20px 20px 0px;
}

.form-btn {
  padding: 8px;
  border: none;
  border-radius: 150px;
  width: 100px;
  background-color: #566DCD;
  color: #fff;
  font-family: 'Roboto';
  font-weight: 900;
  cursor: pointer;
}

.groupForm {
  margin-top: 40px;
}

select {
  width: 100px;
  height: 24px;
  border: 1px solid rgb(209, 209, 209);
}

.tourDesc {
  text-align: left;
  padding: 40px;
  font-size: 13px;
}


.halfFrameTwotourDesc {
  background-image: url('../img/donna1.jpg');
  background-position: center;
  background-size: cover;
  width: 100%;
  padding-left: 20px;
  padding-bottom: 500px;
}

.visit-list {
  padding-left: 20px;
}

.visit-list li {
  line-height: 20px;
  padding-top: 10px;
  font-family: 'Roboto', 'sans-serif';
  font-size: 14px;
}

#circle {
  background: url('../img/donna1.jpg');
  background-size: cover;
  background-position: center top;
}

#NSimage {
  text-align: left;
  max-width: 100%;
  margin-left: 0;
  padding-left: 0;
  margin-top: 10px;
  
  
}

