#Nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 80px 20px 80px;
  margin: 0 auto;
  background: #fff;
}

.topNav {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid rgb(231, 231, 231);
  display: flex;
  justify-content: center;
}

.topNav p {
  padding-left: 10px;
}

.phone {
  width: 15px;
}

.topNav p{
  color: #000;
  font-family: 'Roboto', 'Helvetica', 'sans-serif';
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 13px;
  
}


.links {
  display: flex;
  padding-top: 10px;
  
}

.res-nav-items {
  visibility: hidden;
  position: absolute;
}
.res-nav {
  visibility: hidden;

}
.burger {
  display: hidden;
  position: absolute;
}

.burger:hover {
  cursor: pointer;
}

.x {
  visibility: hidden;
  position: absolute;
}

.burger:hover {
  cursor: pointer;
}


.hide {
  transition: visibility 0.5s, opacity 0.5s linear;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.res-nav ul {
  list-style-type: none;
}

.links {
  display: flex;
}

.links ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
}

.link {
  padding-left: 50px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  font-family: "Roboto", "sans-serif";
  font-weight: 800;
  letter-spacing: 2px;
  font-size: 12px;
}

.link:hover {
  color: grey;
}



.branding img {
  height: 50px;
  cursor: pointer;
}

.branding {
  padding: 0;
}


@media screen and (max-width: 900px) {
  #Nav{
    padding: 0;
  }

  .topNav {
    display: none;
  }

  .mobile-wide-container {
    width: 100%;
    background: #fff;
    position: fixed;
    z-index: 1000;

  }
  .show {
    z-index: 1500;
    transition: visibility 0s, opacity 0.5s linear;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: visible;
    position: fixed;
    opacity: 1;
  } 

  .mobile-wide {
    display: flex;
    margin: 0 auto;
    width: 85%;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #Nav {
    width: 80%;
    justify-content: space-around;
  }

  .links {
    visibility: hidden;
  }

  .links ul {
    position: static;
  }

  .burger {
    visibility: visible;
    z-index: 10000;
  }

  .burger img {
    height: 25px;
  }

  .show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
  }

  .hide {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.5s linear;
  }


  .burger {
    position: static;
    height: 25px;
    padding-top: 5px;
  }

  #Nav {
    width: 80%;
    margin: 0 auto;
  }


  .res-nav-items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;

  }



  .res-nav-items ul {
    list-style-type: none;
  }

  .res-nav-items ul li {
    list-style-type: none;
    margin: 15vh;
  }

  .res-nav-items a {
    text-decoration: none;
    color: #fff;
    font-family: "Roboto", "Helvetica" "sans-serif";
    font-weight: 600;
    letter-spacing: 10px;
    text-transform: uppercase;
    font-size: 14px;
  }
  .res-nav-items a:hover {
    color: #566DCD;
  }
  .x {
    position: absolute;
    top: 5vh;
    left: 5vw;
    color: #fff;
    visibility: visible;
  }

  .x:hover {
    cursor: pointer;
    color: #566DCD;
  }

}
